import React from 'react';
import {
  Grid,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Paper,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useQuery } from 'react-query';
import { compose } from 'redux';
import { RepeatOrderIcon } from '../components/icons/ItemIcon';

import { getAdminDashboardItems } from '../apis/dashboardApi';

const useStyles = makeStyles((theme) => ({
  body: {
    padding: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
}));

function AdminDashboard() {
  const classes = useStyles();
  const { status: loading, data: dashboardData } = useQuery(
    'getAdminDashboardItems',
    getAdminDashboardItems
  );

  return (
    <div className={classes.body}>
      {loading !== 'success' ? (
        <LinearProgress />
      ) : (
        <>
          <h1>Dashboard</h1>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <h2>Live Orders: {dashboardData.allLiveOrders}</h2>
              <h2>
                <RepeatOrderIcon /> Repeat Live Orders:{' '}
                {dashboardData.allRepeatLiveOrders}
              </h2>
            </Grid>
            <Grid item xs={8}>
              <div style={{ height: '50vh' }}>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell align='right'>
                          # of Repeated Orders
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Verifying</TableCell>
                        <TableCell align='right'>
                          {dashboardData.verifyingItems}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Quoted</TableCell>
                        <TableCell align='right'>
                          {dashboardData.quotedItems}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Processed: Pending Verification</TableCell>
                        <TableCell align='right'>
                          {dashboardData.processedPendingVerificationItems}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Processed: Pending RFQ</TableCell>
                        <TableCell align='right'>
                          {dashboardData.processedPendingRFQItems}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Processed: Pending Supplier</TableCell>
                        <TableCell align='right'>
                          {
                            dashboardData.processingPendingSupplierAcceptanceItems
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Processed: Pending Quote Verification
                        </TableCell>
                        <TableCell align='right'>
                          {
                            dashboardData.processingPendingQuoteVerificationItems
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          Processed: Pending PO Acknowledgement
                        </TableCell>
                        <TableCell align='right'>
                          {
                            dashboardData.processingPendingPOAcknowledgementItems
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Order-in-progress</TableCell>
                        <TableCell align='right'>
                          {dashboardData.orderInProgressItems}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Order ready</TableCell>
                        <TableCell align='right'>
                          {dashboardData.orderReadyItems}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}

function mapStateToProps() {
  return {};
}

function matchDispatchToProps() {
  return {};
}

const withConnect = connect(mapStateToProps, matchDispatchToProps);

export default compose(withConnect)(AdminDashboard);
