import { v4 as uuidv4 } from 'uuid';

const s3Api = require('../apis/s3Api');
import { dfmExtractDimensionsApi } from '../apis/dfmApi';

import { createAlertNotification } from '../apis/alertApi';

import { isEmptyValue } from '../utils/commonUtils';

import {
  CADPART_DIRNAME,
  S3_BUCKETNAME,
  BOM_DIR_NAME,
  CUSTOMER_PO_DIR_NAME,
  REACT_APP_IMGPART_DIRNAME,
  CUSTOMER_NDA_DIR_NAME,
  SUPPLIER_PO_TERMS_DIR_NAME,
  SUPPLIER_PARTNERSHIP_AGREEMENT_DIR_NAME,
  SUPPLIER_INVOICE_DIR_NAME,
  CUSTOMER_QUOTATION_TERMS_DIR_NAME,
  TECHNICAL_DRAWING_EXTRACTOR_UPLOADED_PDF_DIR_NAME,
} from '../constants';

// -------------------------------------------------------------------------------------------------

/**
 * @param {*} s3ObjUrl eg: https://s3-bucket-name.s3.ap-southeast-1.amazonaws.com/s3_object_key
 * @returns s3-bucket-name
 */
export const getBucketNameFromS3Url = (s3ObjUrl) => {
  const url = new URL(s3ObjUrl);
  const hostname = url.hostname;
  return hostname.split('.')[0];
};

export const getCadPartS3Key = (file) => {
  const preFilename = file.name
    .split('.')
    .slice(0, -1)
    .join('.');
  const fileExtension = file.name.split('.').pop();
  return `${CADPART_DIRNAME}/${preFilename}_${Date.now()}.${fileExtension}`;
};

export const getBomS3Key = (file, projectID) => {
  const fileID = uuidv4();
  const fileExtension = file.name.split('.').pop();
  return `${BOM_DIR_NAME}/${projectID}/${fileID}.${fileExtension}`;
};

export const getCustomerPoFileS3Key = (file, itemID) => {
  const fileID = uuidv4();
  const fileExtension = file.name.split('.').pop();
  return `${CUSTOMER_PO_DIR_NAME}/${itemID}/${fileID}.${fileExtension}`;
};

export const getCustomerPoFileMctS3Key = (file, mctID) => {
  const fileID = uuidv4();
  const fileExtension = file.name.split('.').pop();
  return `${CUSTOMER_PO_DIR_NAME}/mct-${mctID}/${fileID}.${fileExtension}`;
};

export const createS3KeyForSupplierInvoiceFile = (combinedPoNumber) => {
  return `${SUPPLIER_INVOICE_DIR_NAME}/Invoice_${combinedPoNumber}_${Date.now()}.pdf`;
};

export const getImageFileS3Key = (file) => {
  const fileID = uuidv4();
  const fileExtension = file.name.split('.').pop();
  return `${REACT_APP_IMGPART_DIRNAME}/${fileID}.${fileExtension}`;
};

export const getCustomerNDAFileS3Key = (file) => {
  const preFilename = file.name
    .split('.')
    .slice(0, -1)
    .join('.');
  const fileExtension = file.name.split('.').pop();
  return `${CUSTOMER_NDA_DIR_NAME}/${preFilename}_${file.lastModified}.${fileExtension}`;
};

export const getSupplierPoTermsS3Key = (file) => {
  const preFilename = file.name
    .split('.')
    .slice(0, -1)
    .join('.');
  const fileExtension = file.name.split('.').pop();
  return `${SUPPLIER_PO_TERMS_DIR_NAME}/${preFilename}_${Date.now()}.${fileExtension}`;
};

export const getSupplierPartnershipAgreementS3Key = (file) => {
  const preFilename = file.name
    .split('.')
    .slice(0, -1)
    .join('.');
  const fileExtension = file.name.split('.').pop();
  return `${SUPPLIER_PARTNERSHIP_AGREEMENT_DIR_NAME}/${preFilename}_${Date.now()}.${fileExtension}`;
};

export const getCustomerQuotationTermsS3Key = (file) => {
  const preFilename = file.name
    .split('.')
    .slice(0, -1)
    .join('.');
  const fileExtension = file.name.split('.').pop();
  return `${CUSTOMER_QUOTATION_TERMS_DIR_NAME}/${preFilename}_${Date.now()}.${fileExtension}`;
};

export const getTechnicalDrawingExtractorUploadedPdfS3Key = (file) => {
  const preFilename = file.name
    .split('.')
    .slice(0, -1)
    .join('.');
  const fileExtension = file.name.split('.').pop();
  return `${TECHNICAL_DRAWING_EXTRACTOR_UPLOADED_PDF_DIR_NAME}/${preFilename}_${Date.now()}.${fileExtension}`;
};

export const generatePresignedUrl = async (
  s3ObjUrl,
  expirationTime = null,
  inlineView = false
) => {
  return s3Api.generatePresignedUrl(s3ObjUrl, expirationTime, inlineView);
};

export const uploadFileToS3 = async (file, s3Key, customFileName = null) => {
  if (isEmptyValue(s3Key)) {
    throw new Error(`s3Key is empty`);
  }
  const fileName = customFileName || file.name;
  return s3Api.uploadFileToS3(file, s3Key, fileName);
};

export const uploadPublicFileToS3 = async (
  file,
  s3Key,
  customFileName = null
) => {
  if (isEmptyValue(s3Key)) {
    throw new Error(`s3Key is empty`);
  }
  const fileName = customFileName || file.name;
  return s3Api.uploadFileToS3(file, s3Key, fileName, true);
};

export const deleteFileFromS3 = async (s3Key) => {
  if (!s3Key) {
    throw new Error(`s3Key is empty`);
  }
  const params = {
    Bucket: S3_BUCKETNAME,
    Key: s3Key,
  };
  return s3Api.deleteObject(params);
};

export const uploadCadFileToS3 = async (file) => {
  const data = await uploadFileToS3(file, getCadPartS3Key(file));
  const fileSize = parseInt(file.size / 1000, 10);
  const s3ObjectUrl = data.Location.split(' ').join('%20');
  return {
    s3ObjectUrl,
    fileSize,
  };
};

export const uploadCadFileToS3AndCallDFM = async (file) => {
  // Run the additional function
  const result = await uploadCadFileToS3(file);
  const { s3ObjectUrl } = result;

  if (isEmptyValue(s3ObjectUrl)) {
    return result;
  }

  dfmExtractDimensionsApi({
    file_url: s3ObjectUrl,
  }).catch((error) => {
    // call BE API to notify discord channel
    const body = {
      title: '[WARNING] [FE] Failed to extract dimensions',
      errorStack: error.stack,
      additionalInfo: {
        file_url: s3ObjectUrl,
      },
    };
    createAlertNotification(body).catch(() => null);
  });

  return result;
};
