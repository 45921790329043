import { BACKEND_SERVICE_URL } from '../constants';
import { getAuthorizedHeader } from '../utils/apiUtils';

export const getDhlRates = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/shipment/dhl/rates`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      return response.json().then((err) => {
        throw new Error(err.message);
      });
    }
    return (await response.json()).data;
  });
};

export const getFedExRates = async (body) => {
  const requestUrl = `${BACKEND_SERVICE_URL}/shipment/fedex/rates`;
  return fetch(requestUrl, {
    method: 'POST',
    headers: getAuthorizedHeader(),
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (!response.ok) {
      return response.json().then((err) => {
        throw new Error(err.message);
      });
    }
    return (await response.json()).data;
  });
};
