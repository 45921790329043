import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles/index';

import { Pagination } from '@material-ui/lab';

import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';

import { Cancel, Check } from '@material-ui/icons';

import FtrChip from '../../components/chips/FtrChip';
import FilesUploadButton from '../../components/FilesUploadButton';
import {
  FtrButton,
  FtrH4,
  FtrTextField,
} from '../../components/ftr-components';
import FtrDismissGuideText from '../../components/ftr-components/FtrDismissGuideText';
import {
  FlexColumn,
  FlexColumnCenter,
  FlexRow,
  FlexRowCenter,
} from '../../components/layouts/FlexLayouts';

import withLoadingBackDropTextHOC from '../../hocs/withLoadingBackDropTextHOC';

import { getItemDetailsApi } from '../../apis/itemApi';
import { generatePresignedUrl } from '../../apis/s3Api';
import {
  addManualBalloonsApi,
  autoRenderBalloonApi,
  deleteBalloonsApi,
  downloadBalloonsReportCsvApi,
} from '../../apis/tdeSymbolDetectionApi';

import { asyncMap } from '../../utils/arrayUtils';
import { isEmptyValue } from '../../utils/commonUtils';
import { exportImagesToPDF } from '../../utils/imagesToPdfUtils';
import {
  downloadS3File,
  getFileNameWithoutExtensionFromUrl,
  isPdfFile,
} from '../../utils/fileUtils';

import useAutoBalloonCanvas from '../../hooks/useAutoBalloonCanvas';

import { getUserIDSelector } from '../../selectors/userSelector';

import { getFileNameFromCadFile } from '../../utils/itemUtils';

import { notifyError, notifySuccess } from '../../services/notificationService';
import { uploadFileToS3 } from '../../services/s3Service';

import { ACTION_TRACKING_TYPE } from '../../constants/actionTrackingConstants';
import { AUTO_BALLOONING_S3_FOLDER } from '../../constants/s3Constants';

import { colors } from '../../palette';

// -------------------------------------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  canvas: {
    zIndex: 10,
    PointerEvent: 'auto',
  },
  icon: {
    '&:hover': {
      opacity: '80%',
    },
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: 16,
  },
  checkIcon: {
    backgroundColor: colors.successGreen,
    padding: 3,
  },
  cancelIcon: {
    backgroundColor: colors.hotRed,
    fill: colors.fontWhite,
    fontSize: 22,
  },
}));

const SELECT_OPTIONS = {
  ITEMS: 'ITEMS',
  PDF_URL: 'PDF_URL',
};

function AutoBalloon(props) {
  const classes = useStyles();

  const { setLoadingMessage = () => {} } = props;

  const userID = useSelector(getUserIDSelector);

  const [selectedOption, setSelectedOption] = React.useState(
    SELECT_OPTIONS.PDF_URL
  );

  const [pdfUrl, setPdfUrl] = React.useState(
    'https://factorem-s3-bucket-staging.s3.ap-southeast-1.amazonaws.com/Public/TEST_TURN_V2_1734922093875.pdf'
  );

  const [itemID, setItemID] = React.useState(null);
  const [itemInfo, setItemInfo] = React.useState(null);
  const [loadingItemInfo, setLoadingItemInfo] = React.useState(false);
  const [selectedTechnicalDrawingFile, setSelectedTechnicalDrawingFile] =
    React.useState(null);

  const [autoBallooning, setAutoBallooning] = React.useState(false);
  const [downloadingPdf, setDownloadingPdf] = React.useState(false);
  const [downloadingReport, setDownloadingReport] = React.useState(false);

  const [originalImageUrls, setOriginalImageUrls] = React.useState([]);
  const [annotatedImageUrls, setAnnotatedImageUrls] = React.useState([]);
  const [annotationData, setAnnotationData] = React.useState([]);
  const [reportCsvUrl, setReportCsvUrl] = React.useState('');
  const [selectedAnnotationIndex, setSelectedAnnotationIndex] =
    React.useState(null);
  const [editBalloonText, setEditBalloonText] = React.useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);

  const openEditBalloonText = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const {
    overlayCanvasRef,
    canvasWrapperRef,
    currentPage,
    pageImageRatioMapping,
    getRectangles,
    setImageUrls,
    setCurrentPage,
    setWrapperDimensions,
    removeAllRectangles,
  } = useAutoBalloonCanvas();

  const totalPages = annotatedImageUrls?.length;

  const technicalDrawings = useMemo(() => {
    if (isEmptyValue(itemInfo)) {
      return [];
    }

    const originalFiles = isEmptyValue(itemInfo.originalFiles)
      ? []
      : itemInfo.originalFiles.split(',');

    const cadFile = isEmptyValue(itemInfo.cadFile)
      ? []
      : itemInfo.cadFile.split(',');

    return [...originalFiles, ...cadFile].filter((fileUrl) =>
      isPdfFile(fileUrl)
    );
  }, [itemInfo]);

  const enableAutoBalloonButton =
    (selectedOption === SELECT_OPTIONS.PDF_URL && !isEmptyValue(pdfUrl)) ||
    (selectedOption === SELECT_OPTIONS.ITEMS &&
      !isEmptyValue(selectedTechnicalDrawingFile));

  // -----------------------------------------------------------------------------------------------

  useEffect(() => {
    if (isEmptyValue(annotatedImageUrls)) {
      setImageUrls([]);
      return;
    }

    asyncMap(annotatedImageUrls, async (imgUrl) =>
      generatePresignedUrl(imgUrl)
    ).then((imageUrls) => {
      setImageUrls(imageUrls);
    });
  }, [annotatedImageUrls]);

  useEffect(() => {
    if (canvasWrapperRef.current) {
      const rect = canvasWrapperRef.current.getBoundingClientRect();
      setWrapperDimensions({
        width: rect.width - 10, // prevent scrollbar
        height: rect.height - 10, // prevent scrollbar
      });
    }
  }, [canvasWrapperRef.current]);

  const resetState = () => {
    setOriginalImageUrls([]);
    setAnnotatedImageUrls([]);
    setAnnotationData([]);
    setReportCsvUrl('');
  };

  const handleAutoBalloon = () => {
    resetState();
    setAutoBallooning(true);

    const fileUrl =
      selectedOption === SELECT_OPTIONS.PDF_URL
        ? pdfUrl
        : selectedOption === SELECT_OPTIONS.ITEMS
          ? selectedTechnicalDrawingFile
          : null;

    if (isEmptyValue(fileUrl)) {
      return;
    }

    autoRenderBalloonApi({ pdfUrl: fileUrl })
      .then((response) => {
        setOriginalImageUrls(response?.original_image_urls);
        setAnnotatedImageUrls(response?.annotated_image_urls);
        const _annotationData = response?.annotation_data || [];
        setAnnotationData(
          _annotationData.filter((data) => !isEmptyValue(data.number))
        );
        setAutoBallooning(false);
      })
      .catch(() => {
        notifyError('Auto balloon failed');
        setAutoBallooning(false);
      });
  };

  const handleAddBalloons = () => {
    const rects = getRectangles();
    if (isEmptyValue(rects)) {
      notifyError('Please draw rectangles first');
      return;
    }

    setLoadingMessage('Adding balloon...');
    const bboxes = rects.map((rect) => rect.getBoundingRect());
    const newBalloon = bboxes.map((bbox) => ({
      text: 'x',
      bbox: [
        Math.round(bbox.left / (pageImageRatioMapping[currentPage] || 1)),
        Math.round(bbox.top / (pageImageRatioMapping[currentPage] || 1)),
        Math.round(bbox.width),
        Math.round(bbox.height),
      ],
      manual: true,
    }));

    const body = {
      original_image_urls: originalImageUrls,
      annotation_data: [...annotationData, ...newBalloon],
      total_balloon: annotationData.length + 1,
    };

    addManualBalloonsApi(body)
      .then((response) => {
        removeAllRectangles();
        notifySuccess('Balloon added successfully');
        setOriginalImageUrls(response?.original_image_urls);
        setAnnotatedImageUrls(response?.annotated_image_urls);
        setAnnotationData(response?.annotation_data || []);
        setReportCsvUrl(null);
        setLoadingMessage('');
      })
      .catch(() => {
        notifyError('Add balloon failed');
        setLoadingMessage('');
      });
  };

  const handleDeleteBalloon = (data) => {
    const body = {
      original_image_urls: originalImageUrls,
      annotation_data: annotationData,
      balloon_removal_number: [data.number],
    };
    setLoadingMessage('Deleting balloon...');
    deleteBalloonsApi(body)
      .then((response) => {
        notifySuccess('Balloon removed successfully');
        setOriginalImageUrls(response?.original_image_urls);
        setAnnotatedImageUrls(response?.annotated_image_urls);
        const _annotationData = response?.annotation_data || [];
        setAnnotationData(
          _annotationData.filter((data) => !isEmptyValue(data.number))
        );
        setReportCsvUrl(response?.report_url);
        setLoadingMessage('');
      })
      .catch(() => {
        notifyError('Balloon removal failed');
        setLoadingMessage('');
      });
  };

  const handleDownloadReportCsv = () => {
    if (!isEmptyValue(reportCsvUrl)) {
      downloadS3File(reportCsvUrl);
      return;
    }

    const pdfFileName = getFileNameWithoutExtensionFromUrl(pdfUrl);

    setDownloadingReport(true);
    const body = {
      annotation_data: annotationData,
      filename: `${pdfFileName}_report`,
    };
    downloadBalloonsReportCsvApi(body)
      .then((response) => {
        setReportCsvUrl(response?.report_url);
        if (!isEmptyValue(response?.report_url)) {
          return downloadS3File(response?.report_url);
        }
      })
      .then(() => setDownloadingReport(false))
      .catch(() => {
        setDownloadingReport(false);
        notifyError('Download report csv failed');
      });
  };

  const handleDownloadPdf = async () => {
    setDownloadingPdf(true);
    const imageUrls = await asyncMap(annotatedImageUrls, async (imgUrl) =>
      generatePresignedUrl(imgUrl)
    );

    await exportImagesToPDF(imageUrls, 'auto_balloon.pdf').catch(() => {
      notifyError('Download pdf failed');
    });

    setDownloadingPdf(false);
  };

  const handleUploadPdfFile = async (files) => {
    const file = files[0];
    const fileName = file.name;
    const data = await uploadFileToS3(
      file,
      `${AUTO_BALLOONING_S3_FOLDER}/${fileName}`,
      fileName
    );

    const s3ObjectUrl = data.Location;

    if (isEmptyValue(s3ObjectUrl)) {
      notifyError('Upload pdf failed');
      return;
    }

    setPdfUrl(decodeURIComponent(s3ObjectUrl));
  };

  const handleEditBalloon = (event, annotationIndex) => {
    setAnchorEl(event.currentTarget);
    setSelectedAnnotationIndex(annotationIndex);
    const data = annotationData[annotationIndex];
    setEditBalloonText(data.text);
  };

  const handleCloseEditBalloonText = () => {
    setAnchorEl(null);
  };

  const handleLoadItemInfo = () => {
    if (isEmptyValue(itemID)) {
      return;
    }

    setLoadingItemInfo(true);
    getItemDetailsApi(itemID)
      .then((info) => {
        setItemInfo(info);
        setLoadingItemInfo(false);
      })
      .catch((err) => {
        setLoadingItemInfo(false);
        notifyError(err.message || 'Load item info failed');
      });
  };

  return (
    <FlexColumn
      style={{
        margin: '2rem 0',
        padding: '0 2rem',
        boxSizing: 'border-box',
        gap: '1rem',
        marginBottom: '1rem',
      }}
    >
      {renderSelectOptions()}
      {selectedOption === SELECT_OPTIONS.PDF_URL && renderPdfUrlField()}
      {selectedOption === SELECT_OPTIONS.ITEMS && renderItemSelection()}
      <FlexRowCenter style={{ gap: '1rem' }}>
        <FtrButton
          color='blue'
          onClick={handleAutoBalloon}
          disabled={!enableAutoBalloonButton || autoBallooning}
          loading={autoBallooning}
        >
          Auto Balloon
        </FtrButton>
        {!isEmptyValue(originalImageUrls) && (
          <FtrButton
            color='blue'
            onClick={handleAddBalloons}
            disabled={isEmptyValue(pdfUrl) || autoBallooning}
            loading={autoBallooning}
          >
            Add Manual Balloons
          </FtrButton>
        )}
      </FlexRowCenter>
      {renderCanvas()}
      {/* {renderImages()} */}
      {!isEmptyValue(originalImageUrls) && (
        <FtrH4>Total Balloons: {annotationData.length}</FtrH4>
      )}
      <FlexRow
        style={{
          gap: '1rem',
          margin: 'auto',
          padding: '0 5rem',
          flexWrap: 'wrap',
          boxSizing: 'border-box',
        }}
      >
        {!isEmptyValue(annotationData) && (
          <FlexRow style={{ width: '100%', boxSizing: 'border-box' }}>
            <FtrDismissGuideText
              text='Click on the balloon to edit the text.'
              userID={userID}
              actionTrackingKey={
                ACTION_TRACKING_TYPE.ADMIN_BALLOON_EDIT_TEXT_DISMISS
              }
            />
          </FlexRow>
        )}
        {annotationData
          .sort((a, b) => a.number - b.number)
          .map((data, index) => {
            const text = `${data.number}: ${data.text}`;
            return (
              <FlexRow key={text}>
                <FtrChip
                  type='info'
                  label={text}
                  tooltip={`Number: ${data.number}, Text: ${data.text}`}
                  onDelete={() => handleDeleteBalloon(data)}
                  onClick={(e) => handleEditBalloon(e, index)}
                />
              </FlexRow>
            );
          })}
      </FlexRow>
      <FlexRowCenter
        style={{ marginTop: '2rem', gap: '1rem', boxSizing: 'border-box' }}
      >
        {!isEmptyValue(annotationData) && (
          <FtrButton
            color='blue'
            onClick={handleDownloadReportCsv}
            disabled={downloadingReport}
            loading={downloadingReport}
          >
            Download Report CSV
          </FtrButton>
        )}
        {!isEmptyValue(annotatedImageUrls) && (
          <FtrButton
            color='blue'
            onClick={handleDownloadPdf}
            disabled={downloadingPdf}
            loading={downloadingPdf}
          >
            Download PDF
          </FtrButton>
        )}
      </FlexRowCenter>
      <Popover
        id={id}
        open={openEditBalloonText}
        anchorEl={anchorEl}
        onClose={handleCloseEditBalloonText}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            marginTop: -8,
            borderRadius: 10,
            paddingTop: 8,
            boxShadow: 'none',
          },
        }}
      >
        <FlexRow>
          <FtrTextField
            label='Balloon Text'
            value={editBalloonText}
            onChange={(e) => setEditBalloonText(e.target.value)}
            fullWidth
            style={{
              marginBottom: 0,
            }}
          />
          <Check
            className={`${classes.icon} ${classes.checkIcon}`}
            onClick={() => {
              const data = annotationData[selectedAnnotationIndex];
              data.text = editBalloonText;
              setAnnotationData([...annotationData]);
              handleCloseEditBalloonText();
            }}
          />
          <Cancel
            className={`${classes.icon} ${classes.cancelIcon}`}
            onClick={() => {
              handleCloseEditBalloonText();
            }}
          />
        </FlexRow>
      </Popover>
    </FlexColumn>
  );

  function renderItemSelection() {
    return (
      <FlexColumnCenter style={{ width: 1000, maxWidth: 1000, margin: 'auto' }}>
        <FlexRowCenter
          style={{
            width: '100%',
            margin: 'auto',
            boxSizing: 'border-box',
          }}
        >
          <FlexRow style={{ flexGrow: 1 }}>
            <FtrTextField
              label='Item ID'
              value={itemID}
              onChange={(e) => setItemID(e.target.value)}
              fullWidth
              style={{
                marginBottom: 0,
              }}
            />
            <FtrButton
              size='small'
              color='gray'
              variant='contained'
              onClick={handleLoadItemInfo}
              disabled={isEmptyValue(itemID) || loadingItemInfo}
              loading={loadingItemInfo}
            >
              Load Item Info
            </FtrButton>
          </FlexRow>
        </FlexRowCenter>
        {!isEmptyValue(technicalDrawings) && (
          <TextField
            id='select-technical-drawing'
            select
            label='Technical Drawing Files'
            value={selectedTechnicalDrawingFile}
            onChange={(evt) =>
              setSelectedTechnicalDrawingFile(evt.target.value)
            }
            fullWidth
            variant='outlined'
            margin='dense'
            style={{ marginTop: '1rem', marginBottom: '0.5rem' }}
          >
            {technicalDrawings.map((url, index) => {
              const encodedFileName = url.split('/').pop();
              const fileName = getFileNameFromCadFile(encodedFileName);
              return (
                <MenuItem key={index} value={url}>
                  {fileName}
                </MenuItem>
              );
            })}
          </TextField>
        )}
      </FlexColumnCenter>
    );
  }

  function renderPdfUrlField() {
    return (
      <FlexRowCenter
        style={{
          width: '100%',
          margin: 'auto',
          boxSizing: 'border-box',
        }}
      >
        <FlexRow style={{ flexGrow: 1 }}>
          <FtrTextField
            label='PDF URL'
            value={pdfUrl}
            onChange={(e) => setPdfUrl(e.target.value)}
            fullWidth
            style={{
              marginBottom: 0,
            }}
          />
        </FlexRow>
        <FilesUploadButton
          buttonText='Upload PDF'
          handleUploadFiles={handleUploadPdfFile}
          multiple={false}
          accept='.pdf'
        />
      </FlexRowCenter>
    );
  }

  function renderSelectOptions() {
    return (
      <FormControl component='fieldset'>
        <RadioGroup
          row
          value={selectedOption}
          onChange={(event) => setSelectedOption(event.target.value)}
        >
          <FormControlLabel
            value={SELECT_OPTIONS.PDF_URL}
            control={<Radio color='primary' />}
            label='PDF Url'
          />
          <FormControlLabel
            value={SELECT_OPTIONS.ITEMS}
            control={<Radio color='primary' />}
            label='Items'
          />
        </RadioGroup>
      </FormControl>
    );
  }

  function renderCanvas() {
    if (isEmptyValue(originalImageUrls)) {
      return;
    }

    return (
      <FlexColumnCenter
        style={{
          padding: '1rem',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <FlexRow style={{ width: '100%', boxSizing: 'border-box' }}>
          <FtrDismissGuideText
            text={
              <>
                Can draw rectangles inside the image then click{' '}
                <span style={{ fontWeight: 'bold', color: colors.blue060 }}>
                  Add Manual Balloons
                </span>{' '}
                button above to add manual balloons.
              </>
            }
            userID={userID}
            actionTrackingKey={
              ACTION_TRACKING_TYPE.ADMIN_BALLOON_DRAW_RECTANGLE_DISMISS
            }
          />
        </FlexRow>
        <div
          ref={canvasWrapperRef}
          style={{
            zIndex: '9',
            visibility: 'visible',
            border: colors.darkGreyBorder,
            width: '100%',
            boxSizing: 'border-box',
            height: 'auto',
            position: 'relative',
            backgroundColor: 'white',
          }}
        >
          <canvas
            id='canvas'
            ref={overlayCanvasRef}
            className={classes.canvas}
          />
        </div>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(_, newPage) => setCurrentPage(newPage - 1)}
        />
      </FlexColumnCenter>
    );
  }
}

export default withLoadingBackDropTextHOC(AutoBalloon);
