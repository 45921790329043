import { jsPDF } from 'jspdf';

export async function exportImagesToPDF(images, fileName = 'images.pdf') {
  let pdf;
  const promises = [];

  images.forEach((imageUrl, index) => {
    const promise = new Promise((resolve) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // Avoid CORS issues
      img.src = imageUrl;

      img.onload = () => {
        const imgAspectRatio = img.width / img.height;

        // Determine orientation based on aspect ratio
        const isLandscape = imgAspectRatio > 1;

        if (!pdf) {
          pdf = new jsPDF(isLandscape ? 'landscape' : 'portrait');
        }

        // Set PDF page size dynamically based on orientation
        const pageWidth = isLandscape
          ? pdf.internal.pageSize.getWidth()
          : pdf.internal.pageSize.getHeight();
        const pageHeight = isLandscape
          ? pdf.internal.pageSize.getHeight()
          : pdf.internal.pageSize.getWidth();

        // Scale image to fit the page dimensions
        let imgWidth, imgHeight;
        if (isLandscape) {
          imgWidth = pageWidth;
          imgHeight = imgWidth / imgAspectRatio;
        } else {
          imgHeight = pageHeight;
          imgWidth = imgHeight * imgAspectRatio;
        }

        // Add a new page if it's not the first image
        if (index > 0) {
          pdf.addPage('a4', isLandscape ? 'landscape' : 'portrait');
        }
        pdf.addImage(img, 'JPEG', 0, 0, imgWidth, imgHeight);
        resolve();
      };
    });
    promises.push(promise);
  });

  return Promise.all(promises).then(() => {
    pdf.save(fileName);
  });
}
